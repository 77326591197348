import React from 'react'

const DocumentPage = ({ location }) => {
  // const searchParams = new URLSearchParams(location.search)
  // const successValue = searchParams.get('success')
  const documentUrl = '/documents/LearnershipSeekerRegistration.pdf'

  // if (!successValue) {
  //   navigate('/')
  // }

  return (
    <div>
      <iframe title="Document Viewer" src={documentUrl} width="100%" height="600px"></iframe>
    </div>
  )
}

export default DocumentPage
